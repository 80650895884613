/* 存放劳动合同路由 */
import { DEFAULT_LAYOUT } from '../base';
// import DEFAULT_LAYOUT from '@/layout/default-layout.vue'
import { AppRouteRecordRaw } from '../types';

const C_CONTRACT: AppRouteRecordRaw = {
  path: 'c_contract',
  name: 'C_Contract',
  meta: {
    locale: '劳动合同',
    requiresAuth: true,
    icon: 'icon-home',
    order: 9,
    hideInMenu: false,
    roles: ['user']
  },
  redirect: {
    name: 'MyContract'
  },
  children: [
    {
      path: 'myContracts',
      name: 'MyContracts',
      component: () =>
        import('@/views/Management/Home/Contract/MyContract/index.vue'),
      meta: {
        locale: '我的合同',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['user'],
        hideInMenu: false
      }
    },
    {
      // 跟管理员合同管理页面共用一个路由，所以需要隐藏
      path: 'renewalCommunications',
      name: 'RenewalCommunications',
      component: () =>
        import(
          '@/views/Management/Home/Contract/RenewalCommunication/index.vue'
        ),
      meta: {
        locale: '续签沟通',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['user'],
        hideInMenu: false
      }
    },
    {
      path: 'renewalCommunicationDetails',
      name: 'RenewalCommunicationDetails',
      component: () =>
        import(
          '@/views/Management/Home/Contract/RenewalCommunication/Details/index.vue'
        ),
      meta: {
        locale: '续签沟通详情',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['user'],
        hideInMenu: true
      }
    },
    {
      path: 'myHandleContract',
      name: 'MyHandleContract',
      component: () =>
        import(
          '@/views/Management/Home/Contract/MyContract/Components/ContractDetail/index.vue'
        ),
      meta: {
        locale: '处理合同',
        requiresAuth: true,
        icon: 'icon-jichushezhi',
        roles: ['user'],
        hideInMenu: true
      }
    }
  ]
  // children: [
  //   {
  //     path: 'myContract',
  //     name: 'C_MyContract',
  //     component: () => import('@/views/Client/Contract/MyContract/index.vue'),
  //     meta: {
  //       locale: '我的合同',
  //       requiresAuth: true,
  //       icon: 'icon-home',
  //       hideInMenu: false,
  //       roles: ['user']
  //     }
  //   },
  //   {
  //     path: 'subContract',
  //     name: 'C_SubContract',
  //     component: () => import('@/views/Client/Contract/SubContract/index.vue'),
  //     meta: {
  //       locale: '下属合同',
  //       requiresAuth: true,
  //       icon: 'icon-home',
  //       hideInMenu: false,
  //       roles: ['user']
  //     }
  //   }
  // ]
};

export default C_CONTRACT;
